import React, { useState } from "react"
import QRCode from 'qrcode.react';
import styles from './download.module.css'
export default function Download(props) {
  let info = null
  if (props && props.location && props.location.state) {
     info = props.location.state 
  } 
  return <div>
  <div className={styles.container}>
      <img style={{width:"15%",height:"100%"}}
           src="https://img.yzcdn.cn/public_files/2018/03/22/7f6c96fa50e7dc953e41538221c4d1db.png"
      />
      <div className={styles.app}>
          <div className={styles.container_body}>
              <div className={styles.topContent}>
                  <h3 className={styles.app_title}>{info && info.desc ? info.desc:''}</h3>
                  <div className={styles.text}>扫描二维码</div>
                  <div className={styles.app_container}>
                      <span className={styles.qrcode_container}>
                          <QRCode value={info && info.url ? info.url:''} size={100} />
                      </span>
                  </div>
              </div>
              <div className={styles.row_line}></div>
          </div>
      </div>
      <img style={{width:"15%",height:"100%"}}
           src="https://img.yzcdn.cn/public_files/2018/03/22/fcf0fce8c06c41a68ccf0b1fc2f8710f.png"
      />
  </div>
</div>
}
